.us-3m .amplify-checkbox__icon {
    background-color: #96284e;
}

.us-2y .amplify-checkbox__icon {
    background-color: #52e070;
}

.us-5y .amplify-checkbox__icon {
    background-color: #e21595;
}

.us-10y .amplify-checkbox__icon {
    background-color: #1ab134;
}

.us-30y .amplify-checkbox__icon {
    background-color: #004dbf;
}

.us3m-2y .amplify-checkbox__icon {
    background-color: #c7cd4c;
}

.us3m-5y .amplify-checkbox__icon {
    background-color: #82337a;
}

.us3m-10y .amplify-checkbox__icon {
    background-color: #3b6900;
}

.us3m-30y .amplify-checkbox__icon {
    background-color: #fdace9;
}

.us2y-5y .amplify-checkbox__icon {
    background-color: #c71e14;
}

.us2y-10y .amplify-checkbox__icon {
    background-color: #01caf3;
}

.us2y-30y .amplify-checkbox__icon {
    background-color: #ff5c54;
}

.us5y-10y .amplify-checkbox__icon {
    background-color: #00978b;
}

.us5y-30y .amplify-checkbox__icon {
    background-color: #9e4100;
}

.us10y-30y .amplify-checkbox__icon {
    background-color: #8f3525;
}